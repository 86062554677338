(function ($) {
	$.accordian = function (element, options) {

		var $c = $(element),

			accordian = (function ($c, o) {
				var defaults = {
					
				}; 

				var _o = {},
					$accordian = null
				;

				var _InitObjects = function () {
					_o = $.extend({}, defaults, o);
				},

				_GetParams = function ($$) {
					var index = $$.attr('data-index');

					_OpenItem(index, $$);
				},

				_OpenItem = function (i, $$) {
					var selector = '.accordion-content[data-index="' + i + '"]';

					$$.toggleClass('open');
					$(selector).slideToggle();
				},

				_BindEvents = function () {
					$('.item-button').click(function(){
						_GetParams($(this));
					});
				},

				init = (function() {
					_InitObjects();
					_BindEvents();
				})();
			}) ($c, options);
	};

	$.fn.accordian = function (options) {
		return this.each(function () {
			if (undefined === $(this).data('accordian')) {
				var plugin = new $.accordian(this, options);
				$(this).data('accordian', plugin);
			}
		});
	};
})(jQuery);